<template>
  <Dialog
    v-model:visible="mostarModal"
    :style="{ width: '450px' }"
    header="Pago de comisiones"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12" v-if="cargandoComponente">
      <div class="p-fluid formgrid grid" v-if="permitirPago">
        <div class="field col-12 md:col-12 text-center">
          <h2>
            <strong>{{ data_pago.nombre_usuario }}</strong>
          </h2>
          <h4>
            A pagar:
            <span class="text-red-500 font-bold">{{
              convertirNumeroGermanicFormat(data_pago.total_a_cobrar_comision) +
              " Bs."
            }}</span>
          </h4>
        </div>
        <div class="field col-12 md:col-12" style="background-color: #f2f2f2">
          <p>
            <i class="pi pi-info-circle text-blue-700 font-bold mr-1"></i>
            <strong class="text-blue-700 font-bold">NOTA: </strong>
            <span style="font-size: 14px">
              El Pago se registrará como un <strong>"EGRESO"</strong> en Caja.
            </span>
          </p>
        </div>
        <div
          :class="
            this.mensaje_saldo_caja === true
              ? 'field col-12 md:col-8'
              : 'hidden'
          "
        >
          <label for="advertencia">
            <strong
              ><i class="pi pi-exclamation-triangle text-red-500"></i>
              ADVERTENCIA:</strong
            >
          </label>
          <div class="p-inputgroup">
            <span class="text-blue-850 font-bold" style="font-size: 13px">
              Pagar hasta dejar Caja en 0.00 Bs.
            </span>
            <Checkbox
              v-model="data_pago.pagar_hasta_caja_cero"
              :binary="true"
              class="p-mr-2"
            />
          </div>
        </div>
        <div
          :class="
            this.mensaje_saldo_caja === true
              ? 'field col-12 md:col-4'
              : 'field col-12 md:col-12'
          "
        >
          <label for="egreso"><strong>MONTO A PAGAR: </strong> </label>
          <InputNumber
            v-model="data_pago.egreso"
            :min="0"
            :max="parseFloat(data_pago.total_a_cobrar_comision)"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :showButtons="true"
            locale="de-ED"
            :inputClass="'text-right'"
          />
        </div>
      </div>
      <div class="grid" v-else>
        <Message class="col-12" :severity="'info'" :closable="false">
          <div class="flex align-items-center justify-content-center">
            <span> PARA REALIZAR PAGOS DEBE ABRIR LA CAJA </span>
            <Button
              label="APERTURAR CAJA"
              class="p-button-success p-button-lg ml-3"
              icon="pi pi-lock-open"
              v-tooltip.top="'Abrir Caja'"
              @click="this.$router.push({ name: 'caja' })"
            />
          </div>
        </Message>
      </div>
    </div>
    <div v-else>
      <div class="grid">
        <h3 class="col-12">Verificando caja ...</h3>
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          class="col-12"
        />
      </div>
    </div>
    <template #footer>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="REALIZAR PAGO"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="savePagoComision"
        :loading="loading"
        :disabled="!permitirPago"
      />
    </template>
  </Dialog>
</template>
<script>
import ComisionService from "@/service/ComisionService";
import CajaService from "@/service/CajaService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pagos_comisiones: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      mostarModal: this.show,
      data_pago: this.pagos_comisiones,
      loading: false,
      permitirPago: false,
      cargandoComponente: false,
      mensaje_saldo_caja: false,
    };
  },
  comisionService: null,
  cajaService: null,
  created() {
    this.comisionService = new ComisionService();
    this.cajaService = new CajaService();
  },
  mounted() {
    this.verificarCajaAbierta();
  },
  methods: {
    imprimirMovimientos(id) {
      this.cajaService.imprimirMovimientosPDF(id).then(() => {});
    },
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirPago = true;
        } else {
          this.permitirPago = false;
        }
        this.cargandoComponente = true;
      });
    },
    ocultarDialog() {
      this.mostarModal = false;
      this.$emit("closeModal");
      this.mensaje_saldo_caja = false;
    },
    savePagoComision() {
      //valido que el monto a pagar sea mayor a 0
      if (this.data_pago.egreso <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El monto a pagar debe ser mayor a 0",
          life: 3000,
        });
        return;
      }
      this.loading = true;
      this.comisionService
        .savePagoComision(this.data_pago)
        .then((data) => {
          if (data.success === false) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 3000,
            });
            this.loading = false;
            this.mensaje_saldo_caja = true;
            return;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Exito",
              detail: data.message,
              life: 3000,
            });
            if (data.id_mov_caja !== null) {
              this.imprimirMovimientos(data.id_mov_caja);
            }

            this.loading = false;
            this.mensaje_saldo_caja = false;
            this.ocultarDialog();
            this.$emit("actualizarListado");
          }
        })
        .catch((data) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
          this.loading = false;
          this.mensaje_saldo_caja = true;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    show(val) {
      this.mostarModal = val;
    },
    pagos_comisiones(val) {
      this.data_pago = val;
      this.data_pago.egreso = parseFloat(
        this.data_pago.total_a_cobrar_comision
      );
    },
  },
};
</script>
import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;
export default class ComisionService {
  async imprimirVenta(id) {
    return await fetchWrapper.getPdf(`${ruta}/venta/imprimir/${id}`);
  }

  async filtrarComisiones(datos) {
    return await fetchWrapper.post(`${ruta}/filtros_comision`, datos);
  }

  async cargarDatosNecesarios() {
    return await fetchWrapper.post(`${ruta}/comision/datos_necesarios`);
  }

  async rechazarPago(datos) {
    return await fetchWrapper.post(`${ruta}/comision/rechazar_pago`, datos);
  }

  async aprobarPago(datos) {
    return await fetchWrapper.post(`${ruta}/comision/aprobar_pago`, datos);
  }

  async savePagoComision(datos) {
    return await fetchWrapper.post(`${ruta}/comision/save_pago`, datos);
  }

  async imprimirDetallePagoComision(id) {
    return await fetchWrapper.getPdf(
      `${ruta}/comision/imprimir_detalle_pago/${id}`
    );
  }
}
